import enLocale from 'element-ui/lib/locale/lang/en'

const kr = {
  // menubar
  dashboard: '홈',
  cFILInvest: 'cFIL',
  CRFIInvest: 'CRFI',
  wallet: '지갑', // ? 保留关键字
  exchange: 'CSO',
  exchangeBtn: '교환',
  aaa: '지갑',
  loading: '로딩 중...',
  purchase: '스테이크',
  cycle1: '기간',
  profit1: '수익율',
  purchaseAmount: 'STAKE 수량',
  inexecution: '현재 거래가 진행중 입니다.',
  // Header
  staking: '대출',
  aboutCrossFi: '홈페이지',
  auditReport: '감사 보고서',
  supportCenter: '문서',
  referralRewards: '추천',
  admin: '관리자',
  borrowMenu: '대출',
  // home
  filStatistics: 'cFIL통계',
  crfiStatistics: 'CRFI 통계',
  fileCoinDepositTotal: '예금총액',
  lendingTotal: '대출총액',
  crfiDepositTotal: '예금총액',
  referralRewards: '인출총액',
  referra: '인출총액',
  // cFIL
  cFILInvestment: 'cFIL 스테이킹',
  CRFIInvestment: 'CRFI 스테이킹',
  totalDeposit: '총 예금',
  day: '일',
  Remaining: '남은시간',
  d: '일',
  h: '시',
  m: '분',
  // Wallet
  walletBalance: '지갑 잔고',
  balance: '잔고',
  swap: '스왑',
  stake: '파워저장',
  myPositions: 'STAKE 내역',
  profit: '수익',
  yesterprofit: '어제의 수입',
  // stake
  stake: '대출',
  stake1: '파워저당',
  staking: '파워저당',
  sFILAssets: 'sFIL 자산',
  pledge: '보증',
  borrow: '대출',
  loanApy: '대출 연이자',
  newProfit: '새로운 수익',
  paymentDue: '최소 대출량',
  pledgeRate: '보증률',
  repay: '상환',
  market: '마켓',
  totalsFIL: '총 sFIL',
  totalHashrate: '총 헤쉬레이트',
  cFILLoan: 'cFIL 대출',
  stakePlaceholder: '대출 금액을 입력해 주십시오.',
  max: '최대',
  availableMarket: '사용가능한 마켓',
  confirm: '확인',
  paused: '서비스 종료',
  totalIssue: '총 발행',
  name: '성함',
  email: '이메일',
  company: '회사',
  nodeNumber: '노드 번호',
  toastPaymentDue: '최소 대출금 보다 작을 수 없습니다.',
  cFILRepay: 'cFIL 상환',
  myLoan: '나의 대출',
  walletBalance: '지갑 잔고',
  trusteeship: '저당파워',
  sellWholesale: 'sfil발행량',

  // rewards
  referralRewards: '추천',
  claim: '링크 복사',
  claimedRewards: '추천보상',
  caimedPromote: '나의 추천',
  invitess:"친구 초대 QR 코드",
  // swap
  filSwap: 'FIL 스왑',
  copy: '복사',
  copySucc: '성공적으로 복사하였습니다.',
  swapPlaceholder: '개수를 입력해 주십시오.',
  addressPlaceholder: '파일코인 주소를 입력해 주십시오.',
  addressError: '주소 입력 오류',
  cfilSwap: 'cFIL 스왑',
  exchangeDescription: '교환 설명',
  exchangeDescriptiontext1: '최소 교환 수량은 5개 입니다.',
  exchangeDescriptiontext2: '교환 후 입금은 10~30분 정도 소요됩니다.',
  exchangeDescriptiontext3:
    'cFIL은 FIL의 1 : 1 토큰으로 사용자는 언제든지 cFIL을 FIL로 교환 할 수 있습니다.',
  exchangeDescriptiontext7: '교환 비용              0.02cFIL      ',
  exchangeDescriptiontext4: '최소 교환 수량 제한 없습니다.',
  exchangeDescriptiontext5: '교환 신청 후 입금은 10~120분 정도 소요됩니다.',
  exchangeDescriptiontext6:
    'cFIL은 FIL의 1 : 1 토큰으로 사용자는 언제든지 cFIL을 FIL로 교환 할 수 있습니다.',
  // base
  assets: '자산',
  cancel: '취소',
  confirm: '확인',
  // title
  connect: '지갑 연결',
  overview: '계정 개요',
  depositCoins: '코인 예금',
  selectUser: '사용자 선택',
  selectLang: '언어 선택',
  copyText: '복사 완료',
  // home
  withdrawAll: '수익 출금',
  clickWithdraw: '원클릭 추출',
  depositdue: '수시입출금 상품이 종료되었으니 인출해 주시기 바랍니다',
  fileconinAddress: 'Filecoin 주소',
  invite: '나의 홍보 코드',
  inviteText:
    '귀하의 프로모션 코드로 cFil에 투자하도록 다른 사람들을 초대하여 보상을 받으세요.',
  exchangeDesc:
    '이 주소로 전송된 파일 코인은 1:1 비율로 cFil로 자동 전환됩니다.',
  contract: '계약',
  repurchase: '환매',
  withdraw: '원금 출금',
  eFilDesc: 'cFil은 Filecoin으로 1:1 비율로 교환 할 수 있습니다.',
  FDDesc: 'CRFI는 스테이킹 및 마이닝을 통해 얻을 수 있습니다.',
  eFilPlaceholder: 'cFil 판매량을 입력해 주십시오',
  invitePlaceholder: '추천인 주소를 입력해 주십시오.',
  FilecoinPlaceholder: 'Filecoin 주소를 입력해 주십시오',
  mining: '추가 이자를 받기 위해서는 코인을 입금해 주십시오.',
  // investment
  current: '현재',
  buy: '구입',
  buyProduct: '상품 구입',
  interest: '이자',
  withtraw: '출금',
  date: '현재 시간',
  cycle: '30일/60일/90일',
  maturity: '선택한 주기 이후 24*3600초',
  desc: '출금 가능',
  buyPlaceholder: '구매량을 입력해 주십시오.',
  position: '내 위치',
  redemption: '현재 {value}개의 제품을 상환 할 수 있습니다.',
  investment: '투자',
  annualized: '연간',
  // assets
  // maturity: 'maturity',
  interestAssets: '이자 자산',
  expireAssets: '자산 만료',
  income: '수입',
  currentInvest: '현재 투자',
  fast: '빠른',
  onDemand: '수시입출금',
  expireDate: '기간 만료',
  // admin
  interestRate: '이자율',
  inviteRate: '초대율',
  rate: '비율',
  interestPool: '이자 풀',
  time: '일',
  charge: '충전',
  edit: '편집',
  config: '설정',
  // toast
  minNumberToast: '최소 교환 수량은 0.1 cFil 입니다.',
  balanceToast: '잔액이 부족합니다. 재확인 후 입력해 주십시오.',
  toast: '구매 개수를 입력해 주십시오.',
  empty: '공백',
  // placeholder
  placeholderFilecoin: '출금수량을 입력해 주십시오.',
  networkErr: '네트워크 오류, 다른 네트워크로 변경하시기 바랍니다.',
  errorMsg: '로딩 실패, 다시 시도해 주시기 바랍니다.',
  message: {
    name: 'Alice',
  },
  // exchange
  exSuccess: '체결량',
  exParticipationTotal: '스왑에 사용된 수량',
  exDateNumber: '발행 번호',
  exChangeRatio: '스왑 비율',
  exTotalExchangePool: 'cFIL 할당',
  exTotalExchangeVolumeOfExchangePool: '풀의 용량',
  exSinglePersonLimit: '인당 최대 신청 수량',
  exRemainingConvertible: '신청수량 / 전체',
  exCurrentTotalParticipation: '신청된 수량',
  exHasParticipation: '참가자 수',
  exChangeFee: '환전수수료',
  exBalance: '남은 수량',
  //How to play
  exHowToPlay: '게임 방법',
  exHTP1: '1. 최소 교환 수량 : 10',
  exHTP2: '2. 계좌이체 시간 : 10~30분',
  exHTP3: '3. cFIL은 FIL의 1:1 앵커 토큰으로 사용자는 언제든지 cFIL을 FIL로 교환할 수 있습니다.',
  exAskTitle: '자주 묻는 질문 (자세한 내용은 여기를 클릭하세요)',
  exAsk1: '1.어떤 스왑에 참여해야 하나요? 두 가지를 동시에 참여할 수 있나요? ',
  exAnswer1: '하나, 또는 둘다 동시에 참여할 수 있습니다.',
  exAsk2: '2.스왑된 CRFI 는 어떻게 사용됩니까?',
  exAnswer2: '스왑이 완료된 CRFI는 모두 소각됩니다. ',
  exAsk3: '3.참여비용(수수료) 은 얼마인가요?',
  exAnswer3: '제한 스왑은 수수료가 없습니다. 무제한 스왑의 경우 스왑에 성공한 CRFI의 10%가 수수료로 발생하며 인출 시 지갑잔고에서 지불됩니다. (지갑에 수수료로 사용될 충분한CRFI를 남겨두시기 바랍니다.) ',
  exAsk4: '4.참여비용(10%의 수수료)은 어떻게 사용되나요?',
  exAnswer4: '수수료는 모두 소각됩니다.',
  //Limit exchange
  exLimitExchange: '제한풀',
  exNumberOfExchanges: '교환 횟수',
  exCRFIBalance: 'CRFI 잔고',
  exUnlimitedRedemption: '무제한풀',
  exNextPreview: '다음 미리보기',
  exStartTime: '시작 시간',
  exEndTime: '종료 시간',
  exStayTuned: '기대해 주세요',
  exPastExchange: '지난 스왑 내역',
  exOver: '종료되었습니다',
  exViewPast: '지난 스왑',
  exPastPeriod: '지난 스왑',
  exNextIssue: '다음 스왑',
  exExtract: '신청하기',
  exNonlimitExchange: '무제한풀',
  exNoData: '데이터 없음',
  exParticipationProcess: '참여 방법',
  exPp1: '1.권한 부여',
  exPp2: '2.교환',
  exPp3: '3.보기',
  exPp4: '4.출금',
  exPpr1: '지갑 권한 부여',
  exPpr2: '교환 시작 – 교환 수량 입력 – 지갑 확인',
  exPpr3: '이벤트 종료 후 교환 결과 확인',
  exPpr4: '교환 완료된 자산 출금',
  exLoading: '거래 네트워크가 확인될 때까지 기다리십시오. 페이지를 닫지 마십시오.',
  exAuth: '권한 부여',
  exWithdrawTip: '거래소 출금',
  exWithdrawRemark: '참고: 교환된 CRFI 및 성공적으로 교환된 cFIL의 나머지 금액은 귀하의 지갑에 적립됩니다.',
  exWithdrawRemark1: '참고: 출금 작업은 지갑에서 차감됩니다.',
  exWithdrawRemark2: 'CRFI는 상환 수수료로 사용되며 상환된 CRFI의 나머지 금액과 성공적으로 상환된 cFIL은 지갑에 적립됩니다.',
  exNoLimit: '무제한',
  exInsufficientBalance: '잔액 불충분',
  exOverLimit: '한도 초과',
  exExchangePool: '교환 풀 용량',
  exTimesTxt: '카운트다운: ',
  
  ...enLocale,
}
export default kr
