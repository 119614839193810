import enLocale from 'element-ui/lib/locale/lang/en'
const cn = {
  // menubar
  dashboard: 'Home',
  cFILInvest: 'cFIL',
  CRFIInvest: 'CRFI',
  wallet: 'Wallet', // ? 保留关键字
  exchange: 'CSO',
  exchangeBtn: 'Exchange',
  aaa: 'Wallet',
  loading: 'Loading...',
  
  purchase: 'Stake',
  cycle1: 'Period',
  profit1: 'APY',
  purchaseAmount: 'Stake Amount',
  
  inexecution: 'There are transactions in progress...',
  
  // Header
  staking: 'Borrow',
  aboutCrossFi: 'Official Website',
  auditReport: 'Audit Report',
  supportCenter: 'Docs',
  referralRewards: 'Invite',
  admin: 'Admin',
  borrowMenu: 'Borrow',
  
  // home
  filStatistics: 'cFIL Statistics',
  crfiStatistics: 'CRFI Statistics',
  // fileCoinDepositTotal: 'Filecoin Deposit Total ',
  fileCoinDepositTotal: 'Deposit Total',
  lendingTotal: 'Lending Total',
  crfiDepositTotal: 'Deposit Total',
  referralRewards: 'Output Total',
  referra: 'Total Output',
  
  // cFIL
  cFILInvestment: 'cFIL STAKING',
  CRFIInvestment: 'CRFI STAKING',
  totalDeposit: 'Total Deposit',
  
  day: 'Days',
  Remaining: 'Remaining',
  d: 'D',
  h: 'H',
  m: 'M',
  
  // Wallet
  walletBalance: 'Wallet Balance',
  balance: 'Balance',
  swap: 'Swap',
  stake: 'Storage pledge',
  myPositions: 'My Stake',
  profit: 'Profit',
  yesterprofit: 'yday Profit',
  
  // stake
  stake: 'Borrow',
  stake1: 'Storage Pledge',
  staking: 'Storage Pledge',
  sFILAssets: 'sFIL Assets',
  pledge: 'Pledge',
  borrow: 'Borrow',
  loanApy: 'Loan APY',
  newProfit: 'New Profit',
  paymentDue: 'Minimum Borrowing',
  pledgeRate: 'Pledge Rate',
  repay: 'Repay',
  market: 'Market',
  totalsFIL: 'Total sFIL',
  totalHashrate: 'Total Hashrate',
  cFILLoan: 'cFIL Loan',
  stakePlaceholder: 'Please input the loan amount',
  max: 'MAX',
  availableMarket: 'Available Market',
  confirm: 'Confirm',
  paused: 'Paused',
  totalIssue: 'Total Issue',
  name: 'Name',
  email: 'Email',
  company: 'Company',
  nodeNumber: 'Node Number',
  toastPaymentDue: 'The loan cannot be less than the minimum value',
  
  cFILRepay: 'cFIL Repay',
  myLoan: 'My Loan',
  walletBalance: 'Wallet Balance',
  trusteeship: 'Trusteeship List',
  sellWholesale: 'sell wholesale sFIL',

  // rewards
  referralRewards: 'Invite',
  claim: 'Copy Link',
  claimedRewards: 'My Reward',
  caimedPromote: 'My Invitation',
  invitess: "Display/Download Your QR Code",
  // swap
  filSwap: 'FIL SWAP',
  copy: 'Copy',
  copySucc: 'Copied successfully',
  swapPlaceholder: 'Please input the amount',
  addressPlaceholder: 'Please input your Filecoin address',
  addressError: 'Address input error',
  cfilSwap: 'cFIL SWAP',
  exchangeDescription: 'Exchange Description',
  exchangeDescriptiontext1: ' The minimum exchange quantity is 5.',
  exchangeDescriptiontext2:
    ' The deposit takes about 10 to 30 minutes after the exchange.',
  exchangeDescriptiontext3:
    ' cFIL is a 1:1 token of FIL, and users can exchange cFIL with FIL at any time.',
  exchangeDescriptiontext7: ' Exchange Cost: 0.02 cFIL.',
  exchangeDescriptiontext4: ' There is no minimum required exchange amount.',
  exchangeDescriptiontext5:
    ' The deposit takes about 10 to 120 minutes after applying for an exchange.',
  exchangeDescriptiontext6:
    ' cFIL is a 1:1 token of FIL, and users can exchange cFIL with FIL at any time.',
  
  // base
  assets: 'assets',
  cancel: 'Cancel',
  confirm: 'Confirm',
  // title
  connect: 'connect wallet',
  overview: 'account overview',
  depositCoins: 'deposit coins',
  selectUser: 'select User',
  selectLang: 'select Language',
  copyText: 'copied',
  
  // home
  fileconinAddress: 'Filecoin address',
  invite: 'my promo code',
  inviteText:
    'get rewarded by inviting the others to invest on cFil with your promo code',
  exchangeDesc:
    'Filecoin transfered to this address will be automatically redeemed to cFil in 1:1 ratio',
  contract: 'contract',
  repurchase: 'repurchase',
  withdraw: 'Withdraw Stake + Profit',
  withdrawAll: 'Profit Withdrawal',
  clickWithdraw: 'One-click extraction',
  depositdue: 'Demand deposit has been taken off the shelf. Please pick it up as soon as possible',
  eFilDesc: 'cFil can be redeemed in 1:1 ratio by Filecoin',
  FDDesc: 'CRFI can be obtained by staking and mining',
  eFilPlaceholder: 'please input your cFil selling amount',
  invitePlaceholder: 'please input your recommender address',
  FilecoinPlaceholder: 'Please in your Filecoin address',
  mining: 'deposit coins for extra interest',
  
  // investment
  current: 'current',
  buy: 'buy',
  buyProduct: 'buy product',
  interest: 'interest',
  withtraw: 'withdraw',
  date: 'current time',
  cycle: '30/day/60day/90day',
  maturity: '24*3600s after the selected cycle',
  desc: 'withdrawable',
  buyPlaceholder: 'please input your buyin amount',
  
  position: 'my position',
  redemption: '{value} value product could be redeemed currently',
  investment: 'investment',
  annualized: 'annualized',
  
  // assets
  // maturity: 'maturity',
  interestAssets: 'interest assets',
  expireAssets: 'expire assets',
  income: 'income',
  currentInvest: 'current invest',
  fast: 'fast',
  onDemand: 'On Demand',
  expireDate: 'expire date',
  
  // admin
  interestRate: 'interest rate',
  inviteRate: 'invite rate',
  rate: 'rate',
  interestPool: 'interest pool',
  
  time: 'day',
  
  charge: 'charge',
  edit: 'edit',
  config: 'configuration',
  
  // toast
  minNumberToast: 'Minimum Exchange Quantity is 0.1 cFil.',
  
  balanceToast: 'insufficient balance, please input after double check',
  toast: 'please input the amount of your buyin',
  empty: 'empty',
  // placeholder
  placeholderFilecoin: 'Please enter the withdrawal amount.',
  networkErr: 'Network error, please switch to another network',
  errorMsg: 'Loading failed, please try again',
  message: {
    name: 'Alice',
  },
  // exchange
  exSuccess: 'Success',
  exParticipationTotal: 'Exchanged quantity',
  exDateNumber: 'Date Number',
  exChangeRatio: 'Swap Ratio',
  exTotalExchangePool: 'Quota of cFIL',
  exTotalExchangeVolumeOfExchangePool: 'Total Exchange Volume Of ExchangePool',
  exSinglePersonLimit: 'Member Max Apply Amount',
  exRemainingConvertible: 'Current / Total',
  exCurrentTotalParticipation: 'Current Total Amount',
  exHasParticipation: 'Number of participants',
  exChangeFee: 'Change Fee',
  exBalance: 'Net Balance',
  
  //How to play
  exHowToPlay: 'How To Play',
  exHTP1: '1. Minimum exchange quantity: 10',
  exHTP2: '2. Time for redemption to the account: 10-30 minutes',
  exHTP3: '3. cFIL is the 1:1 anchor token of FIL. Users can exchange cFIL into FIL at any time. Please make sure to transfer tokens other than FIL accident to this address.',
  exAskTitle: 'Transaction detail (Click here to read the detailed process)',
  exAsk1: '1. Which swap should I participate in? Can I participate in both at the same time?',
  exAnswer1: 'You can participate in one or both at the same time.',
  exAsk2: '2. How are swapped CRFI coins used?',
  exAnswer2: 'Successfully swapped CRFI will be burned.',
  exAsk3: '3. How much does it cost to participate?',
  exAnswer3: 'Limited swaps are free but for Unlimited swaps, a 10% fee will be charged for a successful swap. The fee will be deducted from your wallet balance during withdrawal. Please ensure you have sufficient CRFI in your wallet.',
  exAsk4: '4. How is the participation fee used?',
  exAnswer4: 'Participation fee will be burned.',
  
  //Limit exchange
  exLimitExchange: 'Limited',
  exNumberOfExchanges: 'Number Of Exchanges',
  exCRFIBalance: 'CRFI Balance',
  exUnlimitedRedemption: 'Unlimited',
  exNextPreview: 'Next Preview',
  exStartTime: 'Start Time',
  exEndTime: 'EndTime',
  exStayTuned: 'Stay Tuned',
  exPastExchange: 'Past Exchange',
  exOver: 'Over',
  exViewPast: 'View Past',
  exPastPeriod: 'View Past',
  exNextIssue: 'Next Issue',
  exExtract: 'Extract',
  exNonlimitExchange: 'Unlimited',
  exNoData: 'No Data',
  exParticipationProcess: 'Process',
  exPp1: '1. Authorize Wallet',
  exPp2: '2. Exchange',
  exPp3: '3. Confirmation',
  exPp4: '4. Withdrawal',
  exPpr1: 'Authorize your wallet.',
  exPpr2: 'Start exchange - Input exchange amount - Confirm on wallet.',
  exPpr3: 'Confirm the exchange result after event.',
  exPpr4: 'Withdraw the balance.',
  exLoading: 'Wait for the transaction network to confirm, please do not close the page',
  exAuth: 'Authorize',
  exWithdrawTip: 'Exchange withdrawal',
  exWithdrawRemark: 'Note: The Withrawal will result in a deduction of 0.1333 CRFI as Redemption Fee. The remaining redeemed CRFI, along with successfully redeemed cFIL will be credited to your wallet',
  exWithdrawRemark1: 'Note: The withdrawal operation will be deducted from the wallet',
  exWithdrawRemark2: 'CRFI is used as a redemption fee, the remaining amount of CRFI redeemed and the successfully redeemed cFIL will be credited to your wallet',
  exNoLimit: 'Unlimited',
  exInsufficientBalance: 'Insufficient Balance',
  exOverLimit: 'Over Limited',
  exExchangePool: 'Exchange pool capacity',
  exTimesTxt: 'Countdown:',
  
  ...enLocale,
}

export default cn
